import React, { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import LoginScene from './scenes/Login/LoginScene'
import PackSelectScene from './scenes/PackSelect/PackSelectScene'
import CardStackScene from './scenes/CardStack/CardStackScene'
import { useStore } from './store/store'
import { ValidRoute } from './types'
import useAuth from './hooks/useAuth'
import rotate from './images/icons/rotate.svg'
import './App.scss'

const navigationToComponent = (currentRoute: ValidRoute): JSX.Element => {
  switch (currentRoute) {
    case 'packSelect':
      return <PackSelectScene />
    case 'cardStack':
      return <CardStackScene />
    default:
      return <LoginScene />
  }
}

// This is needed as the nav bar hiding and showing on safari will mess up the viewport
window.onresize = function(): void {
  setTimeout(() => {
    const landscape = window.innerWidth > window.innerHeight
    document.body.style.height = landscape ? '100vh' : '100%'
  }, 500)
}

function App(): JSX.Element {
  useAuth()
  const [isPortrait, setIsPortrait] = useState(window.orientation !== 0)
  const navigationStack = useStore(state => state.navigationStack)
  const currentRoute = navigationStack.length
    ? navigationStack[navigationStack.length - 1]
    : ''

  const onRotate = (): void => {
    setIsPortrait(window.orientation !== 0)
  }

  useEffect(() => {
    // Resize is needed as event not handled correctly on safari
    window.addEventListener('resize', onRotate, false)
    window.addEventListener('orientationchange', onRotate)
    return (): void => {
      window.removeEventListener('resize', onRotate, false)
      window.removeEventListener('orientationchange', onRotate)
    }
  }, [])

  return (
    <div className='App' data-testid='App'>
      <div
        className='rotationBlock'
        style={{ display: isMobileOnly && isPortrait ? 'flex' : 'none' }}
      >
        <img src={rotate} alt='A rotate icon' />
        Please rotate to portrait
      </div>
      {navigationToComponent(currentRoute)}
    </div>
  )
}

export default App
