import { Card, Filters } from '../../types'

export default function applyFilters(cards: Card[], filters: Filters): Card[] {
  return cards.filter(card => {
    return Object.keys(filters).every(filterKey => {
      const value = filters[filterKey]
      if (!value || !value.length) return true
      // Filters can be arrays or strings
      if (typeof value === 'string' && value) {
        return card.filters[filterKey] === value
      } else if (Array.isArray(value) && value.length) {
        // Check if there's any overlap
        if (card.filters[filterKey]) {
          return value.some(r => card.filters[filterKey].includes(r))
        }
      }
      return false
    })
  })
}
