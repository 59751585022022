import React from 'react'
import leaderTitle from '../../../images/cardImages/leaderTitle.svg'

export default function TitleCard({
  type,
}: {
  type: 'leader' | 'member'
}): JSX.Element {
  return (
    <div className='titleCard'>
      <h1>{type === 'leader' ? 'Leader' : 'Team Member'} Cards</h1>
      <img src={leaderTitle} alt='A border for the title' />
    </div>
  )
}
