import create from 'zustand'
import dayjs from 'dayjs'
import { CardPack, CardPackList, ValidRoute } from '../types'

export const [useStore] = create(set => ({
  authToken: '',
  authExpiry: null,
  cardPacks: [],
  activePack: null,
  navigationStack: [],
  cardFetchError: false,

  // Auth
  setAuthToken: (authToken: string): void => {
    const authExpiry = dayjs().add(1, 'week')
    set({ authToken, authExpiry })
    localStorage.setItem('authToken', authToken)
    localStorage.setItem('authExpiry', authExpiry.format())
  },
  restoreAuthToken: (authToken: string, authExpiry: string): void =>
    set({ authToken, authExpiry }),
  invalidateAuthToken: (): void => set({ authToken: null }),
  logout: (): void => {
    set({
      authToken: null,
      cardPacks: [],
      activePack: null,
      navigationStack: [],
      cardFetchError: false,
    })
    localStorage.setItem('authToken', '')
    localStorage.setItem('authExpiry', '')
    localStorage.setItem('cardPacks', '')
  },

  // Navigation
  // Handled here not react router to avoid ios back functinoality messing with card dnd
  pushRoute: (newRoute: ValidRoute): void =>
    set(({ navigationStack }) => ({
      navigationStack: [...navigationStack, newRoute],
    })),
  popRoute: (): void =>
    set(({ navigationStack }) => ({
      navigationStack: navigationStack.slice(0, -1),
    })),

  // Cards
  setCardFetchError: (isError: boolean): void =>
    set({ cardFetchError: isError }),
  setActivePack: (activePack: string): void => set({ activePack }),
  addCardPack: (newPack: CardPack): void =>
    set(({ cardPacks }) => ({ cardPacks: [...cardPacks, newPack] })),
  addCardPacks: (newPacks: CardPackList): void =>
    set(({ cardPacks }) => ({ cardPacks: [...cardPacks, ...newPacks] })),
  removeCardPack: (id: number): void =>
    set(({ cardPacks }) => ({
      cardPacks: cardPacks.filter((pack: CardPack) => pack.id !== id),
    })),
  removeCardPacks: (ids: Array<number>): void =>
    set(({ cardPacks }) => ({
      cardPacks: cardPacks.filter((pack: CardPack) => !ids.includes(pack.id)),
    })),
  setCardPacks: (cardPacks: CardPackList): void => {
    set(() => ({ cardPacks }))
    localStorage.setItem('cardPacks', JSON.stringify(cardPacks))
  },
}))
