import { useEffect } from 'react'
import dayjs from 'dayjs'
import { useStore } from '../store/store'

export default function(): void {
  const restoreAuthToken = useStore(state => state.restoreAuthToken)
  const pushRoute = useStore(state => state.pushRoute)
  useEffect(() => {
    const authToken = localStorage.getItem('authToken')
    const authExpiry = localStorage.getItem('authExpiry')
    if (authToken && authExpiry && !dayjs(authExpiry).isBefore(dayjs())) {
      restoreAuthToken(authToken, authExpiry)
      pushRoute('packSelect')
    }
  }, [pushRoute, restoreAuthToken])
}
