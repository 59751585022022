/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useState, useEffect, useCallback } from 'react'
import { useSprings, animated, interpolate } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { useDebouncedCallback } from 'use-debounce'
import { isMobileOnly, isTablet } from 'react-device-detect'
import ConversationCard from '../ConversationCard/ConversationCard'
import { CardDeckProps, AnimTransform } from '../../types'
import Icon from '../shared/Icon/Icon'
import { useWindowSize } from '../../hooks/useWindowResize'
import useEnlarge from '../../hooks/useEnlarge'
import './CardDeck.scss'

const scale = isMobileOnly ? 2.1 : isTablet ? 1.5 : 1.3
const animationTime = 500
const baseScale = 1 / scale
const cardWidth = 600
const cardHeight = 846

// Modified from https://codesandbox.io/embed/j0y0vpz59

// These are just helpers, they curate spring data, values that are later being interpolated into css
const baseState = (
  i: number,
  count: number,
  remaining: number,
): AnimTransform => ({
  x: 0,
  scale: baseScale,
  rot: 0,
  zIndex: 'unset',
  delay: i * (animationTime / count) - (animationTime / count) * remaining,
  pointerEvents: 'all',
})

const to = (i: number, count: number, remaining: number): AnimTransform => ({
  ...baseState(i, count, remaining),
  y: 0,
})
const from = (): AnimTransform => ({
  x: 0,
  rot: 0,
  zIndex: 'unset',
  scale: baseScale + 0.5,
  y: -1000,
  pointerEvents: 'all',
})

// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r: number, s: number, x: number, y: number): string =>
  `rotateY(${r / 10}deg) scale(${s}) translateX(${x}px) translateY(${y}px)`

export default function CardDeck({
  cards,
  name,
  resetActiveFilters,
  onExpand,
}: CardDeckProps): JSX.Element {
  const [width, height] = useWindowSize()
  const [discarded] = useState<Set<number>>(() => new Set()) // The set flags all the cards that are flicked out
  const [expanded, setExpanded] = useState<number | null>(null)
  const [neverExpanded, setNeverExpanded] = useState(true)
  const [enlargedScale, setEnlargedScale] = useState(0)
  const [isLandscape, setIsLandscape] = useState(false)
  const cardCount = cards.length
  const [offset, setOffset] = useState(cardCount - 1)

  // Create a bunch of springs using the helpers above
  const [props, set] = useSprings(cardCount, i => ({
    ...to(i, cardCount, 0),
    from: from(),
  }))

  const reset = useCallback(
    (resetFilters = true): void => {
      const remaining = cardCount - discarded.size
      if (resetFilters) resetActiveFilters()
      setOffset(cardCount - 1)
      discarded.clear()
      set((i: number): AnimTransform => baseState(i, cardCount, remaining))
    },
    [cardCount, discarded, resetActiveFilters, set],
  )

  const [debouncedSetScaling] = useDebouncedCallback(
    // Dependant on screen aspect ratio card will enlarge differently, this handles that change
    // Although window resize is unlikley, tablet rotate is very posible
    () => {
      const isLandscape = window.innerWidth > window.innerHeight
      const cardSize = (isLandscape ? cardHeight : cardWidth) * baseScale
      const windowSize = isLandscape ? window.innerHeight : window.innerWidth
      setIsLandscape(isLandscape)
      setEnlargedScale(windowSize / cardSize)
    },
    500,
  )

  useEffect(() => {
    reset(false)
  }, [cards, reset])

  useEffect(debouncedSetScaling, [width, height, debouncedSetScaling])

  const allCardsDiscarded = (): boolean => discarded.size === cardCount

  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useGesture(
    ({ args: [index], down, delta: [xDelta], direction: [xDir], velocity }) => {
      const trigger = !discarded.has(index) && velocity > 0 && xDir < 0 // If you flick hard enough it should trigger the card to fly out, modified to left only
      const revert = discarded.has(index) && down && velocity > 0 && xDir > 0

      if (expanded !== null) return

      if (!down && trigger && expanded === null) {
        // If button/finger's up and trigger velocity is reached, we flag the card ready to fly to the left of the screen
        discarded.add(index)
        setOffset(offset - 1)
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set((i: number): any => {
        if (index !== i) return // We're only interested in changing spring-data for the current spring
        const isDiscarded = discarded.has(index)
        const x = isDiscarded
          ? (window.innerWidth / 2 + 200) * (isMobileOnly ? -1.9 : -1.25)
          : down
          ? xDelta
          : 0 // When a card is discarded it flys out left, otherwise goes back to zero
        let newScale = down ? baseScale + 0.1 : baseScale // Active cards lift up a bit
        if (revert && isDiscarded) {
          discarded.delete(index)
          setOffset(offset + 1)
        }
        if (discarded.has(index)) newScale = baseScale - 0.1 // Scale discarded cards
        return {
          x,
          scale: newScale,
          delay: undefined,
          config: {
            friction: 50,
            tension: down ? 800 : isDiscarded ? 200 : 500,
          },
        }
      })
      // Separate set to change z-index instantly
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      set((i: number): any => {
        if (index !== i) return // We're only interested in changing spring-data for the current spring
        return {
          immediate: true,
          zIndex: discarded.has(index) || down ? cardCount - index : 'unset', // Manages z index as needs to change dependant on pile and if currently 'held'
        }
      })
      if (!down && allCardsDiscarded()) {
        setTimeout(() => reset(false), 500) // Delaying gives a nicer feel to the reset
      }
    },
  )

  let scale = enlargedScale * baseScale
  // Handle tablets - otherwise card will overflow the screen
  if (!isLandscape && cardHeight * scale > window.innerHeight * 0.8) {
    scale = (window.innerHeight * 0.8) / cardHeight
  }

  // Half off screen - half card length + small margin
  const zoomOffset = window.innerHeight / 2 / scale - 846 / 2 - 30

  useEnlarge(
    cards,
    enlargedScale,
    expanded,
    discarded,
    isLandscape,
    neverExpanded,
    set,
    baseScale,
    cardHeight,
    zoomOffset,
    scale,
  )

  const getActiveCardId = (): number | null => {
    const index = cardCount - (discarded.size + 1)
    if (!cards[index]) return null
    return cards[index].id
  }

  const expandCurrentCard = (): void => {
    if (discarded.size !== cardCount) {
      const expandedCard = expanded !== null ? null : getActiveCardId()
      setExpanded(expandedCard)
      setNeverExpanded(false)
      if (onExpand) onExpand(expandedCard)
    }
  }

  // Now we're just mapping the animated values to our view, that's it.
  return (
    <div className='CardDeck' data-testid='CardDeck'>
      <span
        className='ctaIcon top right'
        style={{ opacity: expanded !== null ? 0.5 : 1 }}
        onClick={(): false | void => {
          if (expanded === null) reset()
        }}
      >
        <Icon icon='reset' />
        <p>Reset Deck</p>
      </span>
      <span
        className='ctaIcon bottom right'
        // Stop width change on content change
        style={{ width: '50px' }}
        onClick={expandCurrentCard}
      >
        <Icon icon={expanded !== null ? 'minimise' : 'expand'} />
        <p>{expanded !== null ? 'Minimise' : 'Expand'}</p>
      </span>
      <div className='Cards'>
        {props.map(({ x, y, rot, zIndex, scale }, i) => {
          let lastGesture = 0
          const card = cards[i]
          // is it the top card, or the last one we discarded, or the next one
          const active =
            i === offset ||
            i === offset - 1 ||
            i === offset + 1 ||
            i === offset - 2 ||
            i === offset + 2
          if (!active) return null

          const content = (
            /* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */
            /* The scroll prop is needed as cards can only be scrolled if expanded */
            <ConversationCard
              {...card}
              key={card.id + 'content'}
              scroll={card.id === expanded}
              name={name}
            />
          )
          return (
            <animated.div
              key={card.id}
              className='AnimatedStack'
              data-active={active}
              {...(active ? bind(i) : {})}
              onClick={(): void => {
                const isDiscarded = discarded.has(i)
                if (!isDiscarded) {
                  const time = +new Date()
                  if (lastGesture > 0 && time - lastGesture < 250) {
                    expandCurrentCard()
                    return
                  }
                  lastGesture = time
                }
              }}
              style={{
                // @ts-ignore
                transform: interpolate([rot, scale, x, y], trans),
                boxShadow: active ? '' : 'none', // Needed as else the box shadows will stack
                zIndex,
              }}
            >
              {content}
            </animated.div>
          )
        })}
      </div>
    </div>
  )
}
