import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import Logo from '../../images/leader-box-logo.svg'
import useFetchCards from '../../hooks/useFetchCards'
import { Button } from '../../components/shared'
import Icon from '../../components/shared/Icon/Icon'
import { useStore } from '../../store/store'
import { CardPack, BoxFilters } from '../../types'
import './PackSelectScene.scss'

const isComingSoon = (filters: BoxFilters): boolean => {
  return !!filters?.tag?.content.find(tag => tag.slug === 'coming-soon')
}

export default function PackSelectScene(): JSX.Element {
  const cardPacks = useStore(state => state.cardPacks)
  const pushRoute = useStore(state => state.pushRoute)
  const cardFetchError = useStore(state => state.cardFetchError)
  const setActivePack = useStore(state => state.setActivePack)
  const logout = useStore(state => state.logout)
  const [showLoading, setShowLoading] = useState(false)

  useFetchCards()

  // Only want to show loading if it takes more than one second
  useEffect(() => {
    const id = setTimeout(() => setShowLoading(true), 1000)
    return (): void => clearTimeout(id)
  }, [])

  const MenuItem = ({ pack }: { pack: CardPack }): JSX.Element => {
    const { name, id, filters, owned, licenceType, link } = pack
    if (isComingSoon(filters)) {
      return (
        <Button
          key={id}
          text='Coming Soon...'
          type='listItem inactive'
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick={(): void => {}}
          disabled={true}
        />
      )
    }
    if (!owned) {
      return (
        <>
          <Button
            key={id}
            text={name}
            type='listItem'
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={(): void => {}}
            disabled={true}
          >
            <span className={classNames('purchaseCTA', licenceType)}>
              {licenceType === 'single' ? (
                <Button
                  key={id}
                  text='Buy Now'
                  type='primary'
                  onClick={(): void => {
                    window.open(link)
                  }}
                />
              ) : (
                <span>Contact your account holder</span>
              )}
            </span>
          </Button>
        </>
      )
    }
    return (
      <Button
        key={id}
        text={name}
        type='listItem'
        onClick={(): void => {
          setActivePack(id)
          pushRoute(`cardStack`)
        }}
      />
    )
  }

  const loading = !cardPacks.length

  return (
    <div className='PackSelectScene' data-testid='PackSelectScene'>
      <img
        className='logo'
        alt='leader box logo, text below is toolkit for leaders'
        src={Logo}
      />
      <p style={{ marginTop: 5 }}>disruptivehr.com</p>
      {cardFetchError ? (
        <div className='fetchError'>
          <h1>Looks like something went wrong</h1>
          <p>
            Please check your internet connection, why not try logging out and
            logging back in again?
          </p>
        </div>
      ) : (
        <span className='packsList'>
          {!loading ? (
            cardPacks.map((pack: CardPack) => (
              <MenuItem key={pack.id} pack={pack} />
            ))
          ) : showLoading ? (
            <h1 className='loading'>Loading...</h1>
          ) : null}
        </span>
      )}
      <span
        className={classNames('logout', loading && 'logOutLoading')}
        onClick={logout}
      >
        <Icon icon='logOut' />
        <span>Logout</span>
      </span>
    </div>
  )
}
