import React from 'react'

export default function CardContainer({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  return (
    <div className='ConversationCard'>
      {children}
      <span className='url'>disruptivehr.com</span>
    </div>
  )
}
