import { CardPack, rawAPIBox, BoxFilters } from '../../types'
import parseCard from './parseCard'

export default function(box: rawAPIBox): CardPack {
  // Want filters in a particular order but not orderable in the CMS
  const ordered: BoxFilters = {}
  Object.keys(box.taxonomies)
    .sort()
    .forEach(function(key) {
      ordered[key] = box.taxonomies[key]
    })

  return {
    id: box.ID,
    name: box.title,
    filters: ordered,
    // TS ignore needed as filter fixes null cards but ts misses this.
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    cards: box?.content
      ?.map(parseCard)
      .filter(card => card)
      .reverse(),
    owned: box.user.owned,
    licenceType: box.user.licenceType,
    link: box.link,
  }
}
