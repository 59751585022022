import { useEffect } from 'react'
import { Card } from '../types'

export default function(
  cards: Array<Card>,
  enlargedScale: number,
  expanded: number | null,
  gone: Set<number>,
  isLandscape: boolean,
  neverExpanded: boolean,
  set: Function,
  baseScale: number,
  cardHeight: number,
  offset: number,
  scale: number,
): void {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set((i: number): any => {
      if (neverExpanded) return // Allows for y transform on load
      if (cards[i].id !== expanded)
        return {
          y: 0,
          scale: gone.has(i) ? baseScale - 0.1 : baseScale,
          pointerEvents: 'all',
        }
      return {
        y: isLandscape ? 0 : -offset,
        scale,
        pointerEvents: 'none',
      }
    })
    // Separate set to increase z-index instantly
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set((i: number): any => {
      if (neverExpanded || cards[i].id !== expanded)
        return {
          immediate: true,
          zIndex: gone.has(i) ? cards.length - i : 'unset',
        } // Allows for y transform on load
      return {
        immediate: true,
        zIndex: cards.length,
      }
    })
  }, [
    baseScale,
    cardHeight,
    cards,
    enlargedScale,
    expanded,
    gone,
    isLandscape,
    neverExpanded,
    offset,
    scale,
    set,
  ])
}
