import React from 'react'
import Toggle from '../Toggle/Toggle'
import './RadioButtonGroup.scss'
import { RadioButtonGroupProps, ToggleItem } from '../../../types'

export default function RadioButtonGroup({
  heading,
  items,
  selected,
  setSelected,
}: RadioButtonGroupProps): JSX.Element {
  return (
    <div className='RadioButtonGroup'>
      <h4 dangerouslySetInnerHTML={{ __html: heading }} />
      {items.map(({ label, id }: ToggleItem) => (
        <Toggle
          key={id}
          checked={selected === id}
          label={label}
          onToggle={(): void =>
            selected === id ? setSelected(null) : setSelected(id)
          }
        />
      ))}
    </div>
  )
}
