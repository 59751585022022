// @ts-nocheck
import { CardPackList, Card } from '../types'
import { mockFilters } from './BoxFiltersMock'
import { mockCardFilters } from './CardFilterMock'

const cards: Array<Card> = [
  {
    blockName: 'drpg-blocks/card',
    id: 0,
    blocks: [
      {
        blockName: 'titles',
        content: {
          title: 'TIPS FOR HAVING A CHECK IN',
          subTitle: 'for leaders',
        },
      },
      {
        blockName: 'core/heading',
        content: '15-20 mins at least once a month',
      },
      {
        blockName: 'core/paragraph',
        content:
          'A check-in is an opportunity to catch up, show appreciation, agree or adjust priorities, discuss recent work and provide coaching. Tell your team when you’re available so they can book their own check-in.',
      },
      {
        blockName: 'core/list',
        content: [
          'A check-in doesn’t need to be long - little and often check-ins are better.',
          'There is no need to keep a record of a check-in  - but you can if you want to, or your team  member can.',
          'Give it your undivided attention. Why not agree to turn your phones off while you have your check-in - oh and try not to cancel.',
          'A check-in can take place anywhere - why not go for a walk, or do over the phone?',
          'This is their performance, ask them to prepare if they need to.',
          'Ask if they want to talk about something else as well e.g. career, health, etc.',
          'A check-in doesn’t need to be long - little and often check-ins are better.',
          'There is no need to keep a record of a check-in  - but you can if you want to, or your team  member can.',
          'Give it your undivided attention. Why not agree to turn your phones off while you have your check-in - oh and try not to cancel.',
          'A check-in can take place anywhere - why not go for a walk, or do over the phone?',
          'This is their performance, ask them to prepare if they need to.',
          'Ask if they want to talk about something else as well e.g. career, health, etc.',
        ],
      },
    ],
    filters: mockCardFilters(),
  },
  {
    blockName: 'drpg-blocks/card',
    id: 1,
    blocks: [
      {
        blockName: 'titles',
        content: {
          title: 'TIPS FOR HAVING CAREER CONVERSATIONS',
          subTitle: 'for leaders',
        },
      },
      {
        blockName: 'core/heading',
        content: '30-45 mins quarterly',
      },
      {
        blockName: 'core/paragraph',
        content:
          'Today’s flatter structures mean it’s not always possible to move up the career ladder. But that doesn’t mean everyone needs to stay in the same place. Career development today is about making the most of your team members’ strengths, giving them the chance to learn new skills and opening doors for them.',
      },
      {
        blockName: 'core/list',
        content: [
          'Match team members’ strengths to movement  and opportunities in the team. ',
          'Be honest and transparent about career opportunities.',
          'Champion advancement - be known for opening  new doors and connecting people.',
        ],
      },
    ],
    filters: mockCardFilters(),
  },
  {
    blockName: 'drpg-blocks/card',
    id: 2,
    blocks: [
      {
        blockName: 'titles',
        content: {
          title: 'TIPS FOR HAVING CAREER CONVERSATIONS',
          subTitle: 'for leaders',
        },
      },
      {
        blockName: 'core/heading',
        content:
          'Facilitated by: Team Leader or why not nominate someone in the team to help facilitate?',
        placeholder: null,
      },
      {
        blockName: 'core/heading',
        content: 'For: the whole team',
        placeholder: null,
      },
      {
        blockName: 'core/heading',
        content: 'Why?',
        placeholder: null,
      },
      {
        blockName: 'core/list',
        content: [
          'Ensure that the goals you set for your team align with those of the broader organisation with whom you need to collaborate&nbsp;',
          'The more you can involve your team in setting goals for themselves, the more committed to those goals they are likely to be&nbsp;',
          'Your motto “Think outcomes not input!”',
          'When setting new goals don’t get too caught up in trying to make them SMART as the focus ends up on the process rather than the outcome',
          'Get the team thinking about a vision of 3–5 objectives, along with 2–3 success measures you’ll use to determine if you’ve reached them',
          'Work in no more than three month sprints to achieve or adapt objectives',
          'Celebrate the wins: If goals are met or exceeded, a reward can make us feel good – whether it’s a shout out at a meeting, a note of recognition, or team drinks',
          'Always allow a moment to recap key decisions or reflect on the insights gained from the meeting and agree on the steps that each person will take – don’t forget to give the introverts in your team a chance to reflect overnight before you make any firm decisions!',
          'Send follow up comms after the meeting with a recap of action items and accountabilities and a date for the next meeting',
          'Use an internal comms channel (e.g. Slack, MS teams, FB workplace, WhatsApp) to share progress&nbsp;',
        ],
        placeholder: null,
      },
    ],
    filters: { ...mockCardFilters(), type: 'conversation' },
  },
  {
    blockName: 'drpg-blocks/intro',
    id: 7,
    header: 'TIPS FOR HAVING A CHECK-IN',
    filters: { type: 'intro' },
  },
  {
    blockName: 'drpg-blocks/rules',
    id: 8,
    header: 'TIPS FOR HAVING A CHECK-IN',
    filters: { type: 'rules' },
  },
  {
    blockName: 'drpg-blocks/leader-title',
    id: 9,
    header: 'TIPS FOR HAVING A CHECK-IN',
    filters: { type: 'memberTitle' },
  },
  {
    blockName: 'drpg-blocks/member-title',
    id: 10,
    header: 'TIPS FOR HAVING A CHECK-IN',
    filters: { type: 'leaderTitle' },
  },
  {
    blockName: 'drpg-blocks/intro',
    id: 11,
    header: 'TIPS FOR HAVING A CHECK-IN',
    filters: { type: 'intro' },
  },
]

export const generateFakeCard = (targetId: string): Card =>
  cards.find(({ id }: { id: number }) => id === targetId) || cards[0]
export const mockPacks: CardPackList = [
  {
    name: 'test pack one',
    id: 0,
    filters: mockFilters,
    cards: [
      generateFakeCard(0),
      generateFakeCard(1),
      generateFakeCard(2),
      generateFakeCard(7),
      generateFakeCard(8),
      generateFakeCard(9),
      generateFakeCard(10),
      generateFakeCard(11),
    ],
    owned: true,
  },
  {
    name: 'test pack two',
    id: 1,
    filters: mockFilters,
    cards: [
      generateFakeCard(0),
      // generateFakeCard('1')
    ],
    owned: true,
  },
]
