// @ts-nocheck
import React, { useRef, useState, useLayoutEffect, useEffect } from 'react'
import classNames from 'classnames'
import { Card, ContentBlock } from '../../types'
import leaderQuoteMark from '../../images/cardImages/leaderQuoteMark.svg'
import Icon from '../shared/Icon/Icon'
import TitleCard from './UniqueCards/TitleCard'
import CardContainer from './CardContainer'
import Block from './Block'
import './ConversationCard.scss'

const Header = ({
  text,
  info,
}: {
  text: string
  info?: string
}): JSX.Element => {
  if (text === undefined && info === undefined) return null
  return (
    <div className={classNames('headerBar')}>
      <h2>{text}</h2>
      {info && <p>{info}</p>}
    </div>
  )
}

const QuoteMark = ({
  floatRight = false,
}: {
  floatRight?: boolean
}): JSX.Element => (
  <img
    src={leaderQuoteMark}
    alt='Decorative Quote marks'
    className={classNames('quoteMark', floatRight && 'flipped')}
  />
)

const OverflowFade = ({ top }): React.ReactNode => {
  return <span className={classNames('overflow', top && 'top')}></span>
}

const ScrollArrow = ({
  orientation,
  show = false,
  hasOverflow,
}: {
  orientation: 'up' | 'down'
  show?: boolean
  hasOverflow: false
}): JSX.Element => {
  return hasOverflow ? (
    <span
      className={classNames('ScrollArrow', orientation)}
      style={{ opacity: show ? 1 : 0 }}
    >
      <Icon icon='backDark' />
    </span>
  ) : null
}

const renderable = (blocks: Array<ContentBlock>): Array<ContentBlock> =>
  blocks.filter(block =>
    ['core/heading', 'core/paragraph', 'core/list'].includes(block.blockName),
  )

function ConversationCard({
  blocks,
  filters = {},
  scroll = false,
  blockName,
}: Card & { scroll?: boolean }): JSX.Element {
  const contentElem = useRef()
  const [orientation, setOrientation] = useState('down')
  const [canScroll, setCanScroll] = useState(false)
  const [hasOverflow, setHasOverflow] = useState(false)

  useLayoutEffect(() => {
    if (contentElem && contentElem.current) {
      setHasOverflow(
        contentElem.current.clientHeight < contentElem.current.scrollHeight,
      )
    }
  }, [blocks])

  useEffect(() => {
    setCanScroll(scroll)
  }, [scroll])

  const onScroll = (e): void => {
    const el = e.target
    if (Math.ceil(el.scrollTop) >= el.scrollHeight - el.offsetHeight - 20) {
      setOrientation('up')
    } else {
      setOrientation('down')
    }
  }

  let content = null
  const { title, subTitle } =
    blocks?.find(({ blockName }) => blockName === 'titles')?.content || {}

  const team = Array.isArray(filters.team) ? filters.team[0] : filters.team
  let className

  switch (blockName) {
    case 'drpg-blocks/rules':
      className = 'Rules'
      break
    case 'drpg-blocks/intro':
      className = 'Intro'
      break
    default:
      className = 'ConversationCard'
  }

  switch (blockName) {
    case 'drpg-blocks/leader-title':
      content = <TitleCard type='leader' />
      break
    case 'drpg-blocks/member-title':
      content = <TitleCard type='member' />
      break
    case 'drpg-blocks/rules':
    case 'drpg-blocks/intro':
    case 'drpg-blocks/card':
      content = (
        <div className={className}>
          <Header text={title} info={subTitle} team={team} />
          <div
            className='content'
            ref={contentElem}
            onScroll={onScroll}
            style={{
              overflow: canScroll || !hasOverflow ? 'auto' : 'hidden',
              height: canScroll || !hasOverflow ? '83%' : '',
            }}
            /*

            Fixed a scroll issue but caused more serious issues on samsung devices
            Can no longer re-create scroll issues either.
            Starting to feel a bit like whack a mole...

            onTouchStart={(event): void => {
              setLastY(event.touches[0].clientY)
            }}
            onTouchMove={(event): void => {
              const top = event.touches[0].clientY

              // Determine scroll position and direction.
              const scrollTop = event.currentTarget.scrollTop
              const direction = lastY - top < 0 ? 'up' : 'down'

              if (scrollTop <= 10 && direction === 'up') {
                // Prevent scrolling up when already at top as this introduces a freeze.
                event.preventDefault()
              } else if (
                scrollTop >=
                  event.currentTarget.scrollHeight -
                    event.currentTarget.clientHeight -
                    10 &&
                direction === 'down'
              ) {
                // Prevent scrolling down when already at bottom as this also introduces a freeze.
                event.preventDefault()
              }

              setLastY(top)
            }} */
          >
            {filters.type === 'conversation' && <QuoteMark type={team} />}
            {blocks &&
              renderable(blocks).map((blockContent, i) => (
                <Block key={i} block={blockContent} />
              ))}
            {filters.type === 'conversation' && (
              <QuoteMark type={team} floatRight={true} />
            )}
          </div>
          {hasOverflow && !scroll && <OverflowFade />}
          <ScrollArrow
            orientation={orientation}
            show={canScroll}
            hasOverflow={hasOverflow}
          />
        </div>
      )
      break
  }

  return <CardContainer>{content}</CardContainer>
}

export default React.memo(
  ConversationCard,
  (prevProps, newProps) => prevProps.scroll === newProps.scroll,
)
