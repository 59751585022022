import React from 'react'
import { IconList, IconProps } from '../../../types'

import eye from '../../../images/icons/eye.svg'
import logOut from '../../../images/icons/logout.svg'
import excamation from '../../../images/icons/important.svg'
import check from '../../../images/icons/tick.svg'
import back from '../../../images/icons/arrow.svg'
import backDark from '../../../images/icons/arrowDark.svg'
import reset from '../../../images/icons/refresh.svg'
import expand from '../../../images/icons/expand.svg'
import minimise from '../../../images/icons/minimise.svg'
import filter from '../../../images/icons/filter.svg'
import close from '../../../images/icons/cross.svg'

const iconList: IconList = {
  eye,
  logOut,
  excamation,
  check,
  back,
  backDark,
  reset,
  expand,
  minimise,
  filter,
  close,
}

export default function Icon({ icon, style = {} }: IconProps): JSX.Element {
  const src = iconList[icon]
  return (
    <img
      src={src}
      style={{ transition: '1s all', ...style }}
      alt={`An icon representing ${icon}`}
    />
  )
}
