import React from 'react'
import classNames from 'classnames'
import './Button.scss'
import { ButtonProps } from '../../../types'

export default function Button({
  text,
  onClick,
  type,
  disabled = false,
  children,
}: ButtonProps): JSX.Element {
  return (
    <button
      className={classNames('Button', type)}
      data-testid='Button'
      onClick={onClick}
      disabled={disabled}
    >
      {text}
      {children}
    </button>
  )
}
