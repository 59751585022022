import { BoxFilters, Filters } from '../../types'

export const generateDefaultFilters = (filters: BoxFilters): Filters => {
  const defaultFilters: Filters = {}
  Object.keys(filters).forEach(key => {
    const filter = filters[key]
    defaultFilters[key] = filter.type === 'multi' ? [] : ''
  })
  return defaultFilters
}
