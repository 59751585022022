import React, { useCallback, useMemo, useState } from 'react'
import { useStore } from '../../store/store'
import CardDeck from '../../components/CardDeck/CardDeck'
import './CardStackScene.scss'
import Icon from '../../components/shared/Icon/Icon'
import FilterModal from '../../components/FilterModal/FilterModal'
import { BoxFilters } from '../../types'
import { generateDefaultFilters } from './generateDefaultFilters'
import applyFilters from './applyFilters'

const customFilterRules = (name: string, filters: BoxFilters): BoxFilters => {
  if (name.toLowerCase() === 'a box of questions') {
    const newFilters = { ...filters }
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    delete newFilters.type
    return newFilters
  }
  return filters
}

export default function CardStackScene(): JSX.Element {
  const cardPacks = useStore(state => state.cardPacks)
  const popRoute = useStore(state => state.popRoute)
  const activePack = useStore(state => state.activePack)

  const { cards, filters, name } = cardPacks.find(
    ({ id }: { id: number }) => id === activePack,
  )

  const [showFilterModal, setShowFilterModal] = useState(false)
  const [activeFilters, setActiveFilters] = useState(
    generateDefaultFilters(filters),
  )
  const [expanded, setExpanded] = useState<number | null>(null)

  const filteredCards = useMemo(() => applyFilters(cards, activeFilters), [
    activeFilters,
    cards,
  ])

  const resetFilters = useCallback(
    (): void => setActiveFilters(generateDefaultFilters(filters)),
    [filters],
  )

  if (!cardPacks.length) return <h1>Loading</h1>

  // If empty or BOQ (custom rule) disable filters
  const hasFilters = Object.keys(filters).length

  return (
    <div className='CardStackScene' data-testid='CardStackScene'>
      <span
        className='ctaIcon top left'
        style={{ opacity: expanded !== null ? 0.5 : 1 }}
        onClick={(
          event: React.MouseEvent<HTMLElement, MouseEvent>,
        ): false | void => {
          if (expanded === null) popRoute(event)
        }}
      >
        <Icon icon='back' />
        <p>Change Box</p>
      </span>
      <span
        className='ctaIcon bottom left'
        style={{
          opacity: expanded !== null || !hasFilters ? 0.5 : 1,
        }}
        onClick={(): false | void => {
          if (expanded === null && hasFilters) {
            // setNeverFiltered(false)
            setShowFilterModal(!showFilterModal)
          }
        }}
      >
        <Icon icon='filter' />
        <p>Filter</p>
      </span>
      {cards && (
        <CardDeck
          cards={filteredCards}
          name={name}
          resetActiveFilters={resetFilters}
          onExpand={setExpanded}
        />
      )}
      {showFilterModal && (
        <FilterModal
          filters={customFilterRules(name, filters)}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          hideModal={(): void => setShowFilterModal(false)}
        />
      )}
    </div>
  )
}
