import { CardFilters } from '../types'

const ff = (): boolean => Math.random() > 0.5

export const mockCardFilters = (): CardFilters => ({
  team: ff() ? 'leader' : 'member',
  cardType:
    (ff() && 'check-in') ||
    (ff() && 'reward') ||
    (ff() && 'career') ||
    'difficult',
  type: 'tips',
})
