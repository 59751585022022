import { BoxFilters } from '../types'

export const mockFilters: BoxFilters = {
  cardType: {
    label: 'Choose a conversation type',
    type: 'multi',
    content: [
      {
        label: 'Check-In',
        slug: 'check-in',
      },
      {
        label: 'Reward',
        slug: 'reward',
      },
      {
        label: 'Career',
        slug: 'career',
      },
      {
        label: 'Difficult',
        slug: 'difficult',
      },
    ],
  },
  team: {
    label: "I'm a team...",
    type: 'single',
    content: [
      {
        label: 'Leader',
        slug: 'leader',
      },
      {
        label: 'Member',
        slug: 'member',
      },
    ],
  },
}
