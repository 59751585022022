import React, { useState } from 'react'
import classNames from 'classnames'
import { FilterBlockProps, FilterModalProps } from '../../types'
import RadioButtonGroup from '../shared/RadioButtonGroup/RadioButtonGroup'
import CheckBoxGroup from '../shared/CheckBoxGroup/CheckBoxGroup'
import './FilterModal.scss'
import { Button } from '../shared'
import Icon from '../shared/Icon/Icon'

const FilterBlock = ({
  id,
  label,
  content,
  type,
  localFilters,
  setLocalFilters,
}: FilterBlockProps): JSX.Element => {
  const Component: typeof CheckBoxGroup | typeof RadioButtonGroup =
    type === 'multi' ? CheckBoxGroup : RadioButtonGroup
  return (
    <Component
      items={
        content ? content.map(({ label, slug }) => ({ label, id: slug })) : []
      }
      heading={label}
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      selected={localFilters[id]}
      setSelected={(newVal: string): void =>
        setLocalFilters({ ...localFilters, [id]: newVal })
      }
    />
  )
}

function getWindowHeight(): number {
  return (
    (document &&
      document.documentElement &&
      document.documentElement.clientHeight) ||
    window.innerHeight
  )
}

export default function FilterModal({
  filters,
  activeFilters,
  setActiveFilters,
  hideModal,
}: FilterModalProps): JSX.Element {
  const [localFilters, setLocalFilters] = useState(activeFilters)
  const [hide, setHide] = useState(false)
  const [viewportHeight] = useState(getWindowHeight())
  return (
    <div
      data-testid='FilterModal'
      className={classNames('FilterModal', hide && 'hide')}
    >
      <div className='background'></div>
      <div className='content' data-testid='content'>
        <h1>Filter</h1>
        {Object.keys(filters).map((key: string) => (
          <FilterBlock
            key={key}
            id={key}
            {...filters[key]}
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
          />
        ))}
      </div>
      <div className='uiOverlay'>
        <div>
          <div className='closeContainer'>
            <span
              onClick={(e): void => {
                setHide(true)
                setTimeout((): void => hideModal(e), 1000)
              }}
              className='close'
              data-testid='close'
            >
              <Icon icon='close' />
            </span>
          </div>
          <div className='buttonContainer' style={{ height: viewportHeight }}>
            <Button
              text='Apply filters'
              onClick={(e): void => {
                setActiveFilters(localFilters)
                setHide(true)
                setTimeout((): void => hideModal(e), 1000)
              }}
              type='primary'
            />
          </div>
          <div className='fadeContainer'>
            <span className='fadeOverlay'></span>
          </div>
        </div>
      </div>
    </div>
  )
}
