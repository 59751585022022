/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useState, useRef } from 'react'
import classNames from 'classnames'
import Logo from '../../images/leader-box-logo.svg'
import { TextInput, Button } from '../../components/shared'
import Icon from '../../components/shared/Icon/Icon'
import { useStore } from '../../store/store'
import { logIn } from '../../network/api'
import './LoginScene.scss'

const isValidEmail = (email: string): boolean => /.+@.+\..+/.test(email)

export default function LoginScene(): JSX.Element | null {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const setAuthToken = useStore(state => state.setAuthToken)
  const inputElement = useRef(null)

  // This could just be a string but splitting up the show state keeps the string in the DOM
  // This stops it just dissapearing as the error tab hides and looking worse
  const [error, setError] = useState({ show: false, text: '' })
  const pushRoute = useStore(state => state.pushRoute)

  const submit = (): void => {
    if (!email)
      return setError({ show: true, text: 'These credentials are incorrect' })
    if (!isValidEmail(email))
      return setError({ show: true, text: 'These credentials are incorrect' })
    if (!password)
      return setError({ show: true, text: 'These credentials are incorrect' })
    // TODO check credentials with API
    setError({ show: false, text: error.text })

    logIn(email, password)
      .then(res => {
        setAuthToken(res)
        pushRoute('packSelect')
      })
      .catch(() =>
        setError({ show: true, text: 'These credentials are incorrect' }),
      )
  }

  const scrollIntoView = (): void => {
    window.scrollTo(
      0,
      // @ts-ignore
      inputElement.current.offsetTop +
        // @ts-ignore
        inputElement.current.clientHeight +
        220,
    )
  }

  return (
    <div className='LoginScene' data-testid='LoginScene'>
      <span className='logoContainer'>
        <img
          className='logo'
          alt='leader box logo, text below is toolkit for leaders'
          src={Logo}
        />
        <p style={{ marginTop: 5 }}>disruptivehr.com</p>
      </span>
      <span className='boxContainer'>
        <span className={classNames('error', error.show && 'active')}>
          <Icon icon='excamation' />
          <span>{error.text}</span>
        </span>
        <div className='box'>
          <TextInput
            label='E-mail'
            value={email}
            onChange={(value: string): void => {
              scrollIntoView()
              setEmail(value)
            }}
          />
          <TextInput
            inputRef={inputElement}
            label='Password'
            value={password}
            onChange={(value: string): void => {
              scrollIntoView()
              setPassword(value)
            }}
            type='password'
          />
          <Button type='primary' text='Login' onClick={submit} />
          <a
            href={`${process.env.REACT_APP_API_ROUTE}/my-account/lost-password/`}
            target='_blank'
            rel='noopener noreferrer'
          >
            Forgot password?
          </a>
        </div>
      </span>
    </div>
  )
}
