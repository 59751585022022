import { useState } from 'react'
import { useStore } from '../store/store'
import { getCardPacks } from '../network/api'
import { CardPackList } from '../types'

const useFetch = (): void => {
  const [fetching, setFetching] = useState(false)
  const { authToken, cardPacks, setCardPacks, setCardFetchError } = useStore(
    ({ authToken, cardPacks, setCardPacks, setCardFetchError }) => ({
      authToken,
      cardPacks,
      setCardPacks,
      setCardFetchError,
    }),
  )
  const storeCards = (packs: CardPackList): void => {
    setFetching(false)
    setCardFetchError(false)
    setCardPacks(packs)
  }
  if (authToken && !cardPacks.length && !fetching) {
    setFetching(true)
    getCardPacks(authToken)
      .then(packs => {
        storeCards(packs)
      })
      .catch(e => {
        if (e.message !== 'Authentication error') {
          // Check cache
          const cachedCardPacks = localStorage.getItem('cardPacks')
          const packs =
            cachedCardPacks !== 'undefined' && JSON.parse(cachedCardPacks || '')
          // Array check avoids cache corruption issues
          if (cachedCardPacks && Array.isArray(packs)) {
            return storeCards(packs)
          }
        }
        return setCardFetchError(true)
      })
  }
}

export default useFetch
