import React from 'react'
import Toggle from '../Toggle/Toggle'
import './CheckBoxGroup.scss'
import { ToggleItem, CheckBoxGroupProps } from '../../../types'

export default function CheckBoxGroup({
  heading,
  items,
  selected,
  setSelected,
}: CheckBoxGroupProps): JSX.Element {
  const toggle = (id: string): void =>
    selected.includes(id)
      ? setSelected(selected.filter((currId: string): boolean => currId !== id))
      : setSelected([...selected, id])
  return (
    <div className='CheckBoxGroup'>
      <h4 dangerouslySetInnerHTML={{ __html: heading }} />
      {items.map(({ label, id }: ToggleItem) => (
        <Toggle
          key={id}
          checked={selected.includes(id)}
          label={label}
          onToggle={(): void => toggle(id)}
        />
      ))}
    </div>
  )
}
