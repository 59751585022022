import React, { useState } from 'react'
import classNames from 'classnames'
import './TextInput.scss'
import Icon from '../Icon/Icon'
import { TextInputProps } from '../../../types'

export default function TextInput({
  label,
  value,
  onChange,
  type = 'text',
  inputRef,
}: TextInputProps): JSX.Element {
  const [focused, setFocused] = useState(false)
  const [forceText, setForceText] = useState(false)
  return (
    <span
      className={classNames(
        'TextInput',
        value || focused ? 'active' : 'inactive',
      )}
      data-testid='TextInput'
    >
      <label htmlFor={label} className='label'>
        {label}
      </label>
      <input
        id={label}
        ref={inputRef}
        onFocus={(): void => setFocused(true)}
        onBlur={(): void => setFocused(false)}
        onChange={(e): void => onChange(e.target.value)}
        type={forceText ? 'text' : type}
        value={value}
      ></input>
      {type === 'password' && value && (
        <span
          className='forceToggle'
          onClick={(): void => setForceText(!forceText)}
        >
          <Icon icon='eye' style={forceText ? { filter: 'invert(1)' } : {}} />
        </span>
      )}
    </span>
  )
}
