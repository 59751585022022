// @ts-nocheck
import React from 'react'
import { ContentBlock } from '../../types'

const Heading = ({ content }: { content: string }): JSX.Element => (
  <h3 className='timing' dangerouslySetInnerHTML={{ __html: content }} />
)

const Paragraph = ({ content }: { content: string }): JSX.Element => (
  <p dangerouslySetInnerHTML={{ __html: content }} />
)

const List = ({ content }: { content: Array<string> }): JSX.Element => {
  const contentCopy = { ...content }
  const { start } = contentCopy
  delete contentCopy.listType
  delete contentCopy.start
  return content.listType === 'ul' ? (
    <ul className='points'>
      {Object.values(contentCopy).map((point, i) => (
        <li key={i} dangerouslySetInnerHTML={{ __html: point }} />
      ))}
    </ul>
  ) : (
    <ol className='points' start={start}>
      {Object.values(contentCopy).map((point, i) => (
        <li key={i} dangerouslySetInnerHTML={{ __html: point }} />
      ))}
    </ol>
  )
}

const typeToComponentMap = {
  'core/heading': Heading,
  'core/paragraph': Paragraph,
  'core/list': List,
}

export default function Block({ block }: { block: ContentBlock }): JSX.Element {
  const Component = typeToComponentMap[block.blockName]
  return <Component content={block.content} />
}
