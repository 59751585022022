import { CardPackList } from '../types'
import { mockPacks } from '../testing/CardPackMock'
import parseBoxList from './parsers/parseBoxList'

const apiRoute = process.env.REACT_APP_API_ROUTE

const cardPackRoute = '/wp-json/drpg/v1/leaderbox-cards'
const loginRoute = '/wp-json/jwt-auth/v1/token'

export const logIn = async (
  email: string,
  password: string,
): Promise<string> => {
  if (process.env.REACT_APP_USE_CARD_MOCKS === 'true')
    return new Promise(resolve => resolve('abc'))

  const formData = new FormData()
  formData.append('username', email)
  formData.append('password', password)

  return fetch(apiRoute + loginRoute, {
    method: 'post',
    body: formData,
  })
    .then(response => response.json())
    .then(response => {
      if (response.statusCode !== 200)
        throw new Error(`Error: status code ${response.statusCode}`)
      return response.data.token
    })
}

export const getCardPacks = async (
  authToken: string,
): Promise<CardPackList> => {
  if (process.env.REACT_APP_USE_CARD_MOCKS === 'true')
    return new Promise(resolve => resolve(mockPacks))

  return fetch(apiRoute + cardPackRoute, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(response => {
      // Error will ben an object e.g. auth error
      if (Array.isArray(response)) {
        return parseBoxList(response)
      } else {
        throw new Error('Authentication error')
      }
    })
}
