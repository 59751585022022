import React from 'react'
import classNames from 'classnames'
import Icon from '../Icon/Icon'
import './Toggle.scss'
import { ToggleProps } from '../../../types'

export default function Toggle({
  label,
  checked,
  onToggle,
}: ToggleProps): JSX.Element {
  return (
    <div
      className={classNames('Toggle', checked && 'checked')}
      onClick={onToggle}
      data-testid='Toggle'
    >
      <span dangerouslySetInnerHTML={{ __html: label }} />
      <span className='tick'>{checked && <Icon icon='check' />}</span>
    </div>
  )
}
