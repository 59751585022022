// @ts-nocheck
import { Card, rawAPICard, ContentBlock, CardFilters } from '../../types'

const parseableCardOptions = [
  'drpg-blocks/card',
  'drpg-blocks/intro',
  'drpg-blocks/rules',
]

const bespokeCardOptions = [
  'drpg-blocks/leader-title',
  'drpg-blocks/member-title',
]

/*
const stripStringHTML = (str: string): string =>
  str.replace(/<\/?[^>]+(>|$)/g, ' ')

const stripBlockHTML = (block: ContentBlock): ContentBlock => {
  switch (block.blockName) {
    case 'titles':
      return {
        ...block,
        content: {
          title: stripStringHTML(block.content.title),
          subHeading: stripStringHTML(block.content.subTitle),
        },
      }
    case 'core/heading':
    case 'core/paragraph':
      return { ...block, content: stripStringHTML(block.content) }
    case 'core/list':
      return {
        ...block,
        content: block.content.map(string => stripStringHTML(string)),
      }
    default:
      return block
  }
}
*/

export default function(cardData: rawAPICard, id: number): Card | null {
  if (parseableCardOptions.includes(cardData.blockName)) {
    const blocks: Array<ContentBlock> = cardData.content.filter(
      contentBlock => contentBlock.blockName !== 'taxonomies',
    )

    const filters: CardFilters =
      cardData.content.find(
        contentBlock => contentBlock.blockName === 'taxonomies',
      )?.content || {}
    if (!cardData.content || !cardData.content.length) return null
    return {
      id,
      blocks: blocks, // blocks.map(block => stripBlockHTML(block)),
      filters,
      blockName: cardData.blockName,
    }
  } else if (bespokeCardOptions.includes(cardData.blockName)) {
    return { id, blocks: [], filters: [], blockName: cardData.blockName }
  }
  return null
}
